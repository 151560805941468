import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Recap from "../components/Recap";
// import Get from "../components/Get";
import "../App.css";
import axios from "axios";
// import htmlToDraft from "html-to-draftjs";

const HomePage = ({ type }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorValue, setEditorValue] = useState("");
  const [editorValue2, setEditorValue2] = useState("");
  // const [getdbvalue, setgetdbvalue] = useState("");

  const token = localStorage.getItem("token")

  const content = JSON.stringify(editorValue);
  const recapcontent = JSON.stringify(editorValue2);
  const [details, setDetails] = useState({
    SubjectTitle: "",
    VideoTitle: "",
  });

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  useEffect(() => {
    const draft = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorValue(draft);
  }, [editorState]);

  const onchange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  // console.log(editorValue)

  const submit = async (e) => {
    e.preventDefault();

    const body = {
      SubjectTitle: details.SubjectTitle,
      VideoTitle: details.VideoTitle,
      courseName:details.courseName,
      content: content,
    };

    if (type == "Notes") {
      //post data to testDB
      if (details.SubjectTitle.toLowerCase() && details.SubjectTitle.toLowerCase() == "html" || details.SubjectTitle.toLowerCase() == "css" || details.SubjectTitle.toLowerCase() == "bootstrap" || details.SubjectTitle.toLowerCase() == "javascript") {
        await axios.post(
          `https://server.techiepanda.in/${details.SubjectTitle.toLowerCase()}/create${details.SubjectTitle.toLowerCase()}`,
          {
            ...body,
          },
          {
            headers:{
              Authorization : "Bearer " + token
            }
          }
        ).then(()=>{
          const inp = document.querySelectorAll("input");
          const edi = document.querySelector(".notranslate");
          inp.forEach((e) => {
            e.value = "";
          });
          edi.innerHTML = "";
          setEditorValue("");
          window.location.reload()

        })

      }else{
        await axios.post(
          `https://rest.techiepanda.in/createContent`,
          {
            ...body,
          },
          {
            headers:{
              Authorization : "Bearer " + token
            }
          }
        ).then(()=>{
          const inp = document.querySelectorAll("input");
          const edi = document.querySelector(".notranslate");
          inp.forEach((e) => {
            e.value = "";
          });
          edi.innerHTML = "";
          setEditorValue("");
          window.location.reload()

        })

      }
    }
    if (type == "Assign") {
      // if (details.SubjectTitle.toLowerCase()) {
        await axios.post(
          "https://rest.techiepanda.in/createAssignment",
          {
            ...body,
          },
          {
            headers:{
              Authorization : "Bearer " + token
            }
          }
        ).then(()=>{
          const inp = document.querySelectorAll("input");
          const edi = document.querySelector(".notranslate");
          inp.forEach((e) => {
            e.value = "";
          });
          edi.innerHTML = "";
          setEditorValue("");
          window.location.reload()

        })
      // }
    }
    
    if (type == "MathAssign") {
      // if (details.SubjectTitle.toLowerCase()) {
        await axios.post(
          "https://rest.techiepanda.in/createAssignment",
          {
            mathAssign :"true",
            ...body,
          },
          {
            headers:{
              Authorization : "Bearer " + token
            }
          }
        ).then(()=>{
          const inp = document.querySelectorAll("input");
          const edi = document.querySelector(".notranslate");
          inp.forEach((e) => {
            e.value = "";
          });
          edi.innerHTML = "";
          setEditorValue("");
          // window.location.reload()
        })
      // }
    }
   
    // setEditorValue2("");
  };

  // const edi = document.querySelector(".notranslate");
  // if (getdbvalue) {
  //   const editordiv = document.querySelector(".notranslate");
  //   let parser = JSON.parse(getdbvalue.content);
  //   edi.innerHTML = parser;
  //   console.log(editordiv.children[0].children);
  // }

  return (
    <div className="editor">
      <div className="input">
        <span>Main Title</span>
        <input
          placeholder="HTML , CSS , BootStrap"
          onChange={(e) => onchange(e)}
          name="SubjectTitle"
        />

        <span>Video Title</span>
        <input
          placeholder="Titles"
          onChange={(e) => onchange(e)}
          name="VideoTitle"
        />

        <span>courseName</span>
        <input
          placeholder="courseName"
          onChange={(e) => onchange(e)}
          name="courseName"
        />
      </div>
      {/* <div className="editors">
        <Recap editorValue2 = {editorValue2} setEditorValue2= {setEditorValue2} />
      <div>
        <h1>Enter the content in this Editor below</h1>  */}
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onEditorStateChange={onEditorStateChange}
      />
      {/* </div>
      </div> */}
      <div className="buttonsDiv">
        {/* <Get
          details={{ ...details, content: content }}
          setgetdbvalue={setgetdbvalue}
        />
        <Update
          details={{ ...details, content: content }}
          setEditorValue={setEditorValue}
          setgetdbvalue={setgetdbvalue}
        /> */}
        <button onClick={submit}>Submit</button>
      </div>
    </div>
  );
};

export default HomePage;
