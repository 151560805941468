/* @flow */

import React, { Component } from "react";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "draft-js";

class ConvertToRawDraftContent extends Component {
  constructor(props) {
    super(props);
    const html = `<p></p>
    hello world
        <p></p>`;
    const contentBlock = htmlToDraft(html);
    console.log(contentBlock);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <div className="rdw-storybook-root">
        <span>
          HTML Content:{" "}
          <pre>{"<p>Hey this <strong>editor</strong> rocks 😀</p>"}</pre>
        </span>
        <Editor
          editorState={editorState}
          toolbarClassName="rdw-storybook-toolbar"
          wrapperClassName="rdw-storybook-wrapper"
          editorClassName="rdw-storybook-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}

export default ConvertToRawDraftContent;
