import React, { useState } from "react";
import HomePage from "./Pages/HomePage";
import ConvertToRawDraftContent from "./Pages/Htmltojson";

const App = () => {
  const [type, setType] = useState("Notes");
  window.addEventListener('message', function(event) {
    if (event.data.type === 'SET_TOKEN') {

        localStorage.setItem('token', event.data.token);
    }
});
  return (
    <>
      <HomePage type={type} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0rem 4rem",
        }}
      >
        <button
          onClick={(e) => setType(e.target.name)}
          style={
            type == "Notes"
              ? {
                  backgroundColor: "orange",
                  padding: "1rem 2rem",
                  border: "none",
                }
              : {
                  backgroundColor: "transparent",
                  padding: "1rem 2rem",
                  border: "none",
                }
          }
          name="Notes"
        >
          Notes Content
        </button>
        <button
          onClick={(e) => setType(e.target.name)}
          style={
            type == "Assign"
              ? {
                  backgroundColor: "orange",
                  padding: "1rem 2rem",
                  border: "none",
                }
              : {
                  backgroundColor: "transparent",
                  padding: "1rem 2rem",
                  border: "none",
                }
          }
          name="Assign"
        >
          Assignment Content
        </button>
        <button
          onClick={(e) => setType(e.target.name)}
          style={
            type == "MathAssign"
              ? {
                  backgroundColor: "orange",
                  padding: "1rem 2rem",
                  border: "none",
                }
              : {
                  backgroundColor: "transparent",
                  padding: "1rem 2rem",
                  border: "none",
                }
          }
          name="MathAssign"
        >
          Math Assignment Content
        </button>
      </div>

      {/* <ConvertToRawDraftContent /> */}
    </>
  );
};

export default App;
